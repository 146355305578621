<template>
  <div id="app">
    <Header />
    <GmtTime />
    <OwnCallsign v-on:set-own-callsign="setOwnCallsign"/>
    <Freq v-on:set-freq="setFreq"/>
    <AddComments v-on:set-comments="setComments"/>
    <AddLog v-on:add-log="addLog"/>
    <flash-message class="myCustomClass"></flash-message>
    <Logs v-bind:logs="logs" v-on:del-log="deleteLog"/>
    <div class="smaller">
      | &nbsp; <a href="/" @click.prevent="download_adi()">Download ADI</a>
      | <a href="/" @click.prevent="download_adiv3()">Download ADI v3</a> 
      | <a href="/" @click.prevent="download_data_json()">Download JSON</a> 
      | <a href="/roadmap.md">Roadmap</a> 
    </div>
    <p>&nbsp;</p>
    <Copyright/>
  </div>
</template>

<script>
// | <a href="/" @click.prevent="spike_process_prefix()">spike_process_prefix</a>
import Header from './components/layout/Header.vue'
import GmtTime from './components/GmtTime'
import OwnCallsign from './components/OwnCallsign'
import Freq from './components/Freq'
import AddComments from './components/AddComments.vue'
import AddLog from './components/AddLog'
import Logs from './components/Logs'
import Copyright from './components/Copyright.vue'

export default {
  name: 'App',
  components: {
    Logs,
    Header,
    OwnCallsign,
    GmtTime,
    AddLog,
    Freq,
    Copyright,
    AddComments
  },
  data() {
    return {
      own_callsign: "",
      freq: "7.130 MHz", 
      logs: [
        {
          id: 1,
          peer_callsign: "N5HN",
          own_callsign: "",
          gmt_time: "2151",
          date: "20210218",
          freq: "7.145 MHz",
          band: "40M",
          khz_freq: 7145,
          logged: false,
          comments: ""
        },
        {
          id: 2,
          peer_callsign: "F4GOH",
          own_callsign: "",
          gmt_time: "2157",
          date: "20210218",
          freq: "7.152 MHz",
          khz_freq: 7152,
          band: "40M",
          logged: false,
          comments: ""
        },
        {
          id: 3,
          peer_callsign: "R1UJX",
          own_callsign: "",
          gmt_time: "2201",
          date: "20210218",
          freq: "7.160 MHz",
          khz_freq: 7160,
          band: "40M",
          logged: true,
          comments: ""
        }
      ]
    }
  },
  mounted() {
    if (this.isLocalStorage()) {
      let flag = false;
      // console.log('Trying to load from localstorage');
      if (localStorage.logs) {
        this.logs = JSON.parse(localStorage.logs);
        flag = true;
      }
      if (localStorage.own_callsign) {
        this.own_callsign = localStorage.own_callsign;
        flag = true;
      }
      if (localStorage.freq) {
        this.freq = localStorage.freq;
        flag = true;
      }
      if (flag) {
        this.flashSuccess("Previous call log data restored (from your browser's local storage)");
      }
    }
  },
  methods: {
    isLocalStorage() {
      var test = 'test';
      try {
          localStorage.setItem(test, test);
          localStorage.removeItem(test);
          return true;
      } catch(e) {
          return false;
      }
    },
    persist() {
      if (this.isLocalStorage()) {
        // console.log('Trying to persist...');
        localStorage.own_callsign = this.own_callsign;
        localStorage.freq = this.freq;
        localStorage.logs = JSON.stringify(this.logs); // https://stackoverflow.com/questions/3357553/how-do-i-store-an-array-in-localstorage
      }
      else
      {
        console.log("LocalStorage not supported");
      }
    },
    deleteLog(id) {
      this.logs = this.logs.filter(log => log.id !== id);
      this.persist();
    },
    getBandFromKhzFreq(freq) {
      // http://www.arrl.org/band-plan
      if (freq >= 7000 && freq <= 7300) {
        return "40M";
      } else if (freq >= 2300000 && freq <= 2310000) {
        return "13CM";
      } else if (freq >= 1240000 && freq <= 1300000) {
        return "23CM";
      } else if (freq >= 902000 && freq <= 928000) {
        return "33CM";
      } else if (freq >= 420000 && freq <= 450000) {
        return "70CM";
      } else if (freq >= 222000 && freq <= 225000) {
        return "1.25M";
      } else if (freq >= 144000 && freq <= 148000) {
        return "2M";
      } else if (freq >= 50000 && freq <= 54000) {
        return "6M";
      } else if (freq >= 28000 && freq <= 29700) {
        return "10M";
      } else if (freq >= 24890 && freq <= 24990) {
        return "12M";
      } else if (freq >= 21000 && freq <= 21450) {
        return "15M";
      } else if (freq >= 18068 && freq <= 18168) {
        return "17M";
      } else if (freq >= 14000 && freq <= 14350) {
        return "20M";
      } else if (freq >= 10100 && freq <= 10150) {
        return "30M";
      } else if (freq >= 5330 && freq <= 5404) {
        return "60M";
      } else if (freq >= 3500 && freq <= 4000) {
        return "80M";
      } else if (freq >= 1800 && freq <= 2000) {
        return "160M";
      } else if (freq >= 135 && freq < 138) {
        return "2200M";
      }
      else return "Unknown";
    },
    computeKhzFreq(freq) { // computes freq in khz
      if (freq.toLowerCase().includes("mhz")) {
        return freq.split(" ")[0] * 1000;
      }
      else if (freq.toLowerCase().includes("khz")) {
        return freq.split(" ")[0];
      }
      else if (freq.toLowerCase().includes("ghz")) {
        return freq.split(" ")[0] * 1000000;
      }
    },
    addLog(newLog) {
      newLog.own_callsign = this.own_callsign;
      newLog.freq = this.freq;
      newLog.khz_freq = this.computeKhzFreq(newLog.freq);
      newLog.band = this.getBandFromKhzFreq(newLog.khz_freq);
      let date = new Date();
      newLog.gmt_time = "" + date.getUTCHours().toString().padStart(2, "0") + date.getUTCMinutes().toString().padStart(2, "0");
      newLog.date = "" + date.getUTCFullYear().toString().padStart(4, "0") + (date.getUTCMonth()+1).toString().padStart(2, "0") + date.getUTCDate().toString().padStart(2, "0");
      newLog.comments = this.comments;
      // Insert the log entry at the begginning of the logs
      this.logs = [newLog, ...this.logs];
      this.persist();
    },
    setFreq(newFreq) {
      // console.log(newFreq);
      this.freq = newFreq.freq;
    },
    setComments(newComments) {
      // console.log(newComments);
      // console.log(this);
      this.comments = newComments.comments;
    },
    setOwnCallsign(newCallsign) {
      this.own_callsign = newCallsign.own_callsign;
    },
    create_adi_portion(name, value) {
      return(`<${name}:${value.length}>${value}`);
    },
    create_adiv3() {
      // https://github.com/k0swe/adif-parser-ts/blob/main/test/adif-parser.test.ts
      let response = `Generated by SimpleHAM http://www.SimpleHAM.com

<adif_ver:5>3.0.5
<programid:9>SimpleHAM
<EOH>\n` + this.create_adi();
      return(response);
    },
    create_adi() {
      let res = "";
      // ADI Format: https://www.adif.org/100/adif_100.htm
      this.logs.forEach(element => {
        if (element.comments == undefined) {
          element.comments = " ";
        }
        res = res + this.create_adi_portion("call", element.peer_callsign)
                  + this.create_adi_portion("band", element.band)
                  + this.create_adi_portion("mode", "SSB")
                  + this.create_adi_portion("qso_date", element.date)
                  + this.create_adi_portion("time_on", element.gmt_time)
                  + this.create_adi_portion("OPERATOR", this.own_callsign)
                  + this.create_adi_portion("FREQ", element.freq)
                  // + this.create_adi_portion("", )
                  + this.create_adi_portion("COMMENT", element.comments) // COMMENT
                  + "<eor>\n";
      }
      );
      return(res);
    },
    download_adi() {
      // https://stackoverflow.com/questions/53772331/vue-html-js-how-to-download-a-file-to-browser-using-the-download-tag
      const blob = new Blob([this.create_adi()], { type: 'application/text' })
      const link = document.createElement('a')
      link.download = "SimpleHAM.adi.txt";
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    },
    download_adiv3() {
      // https://stackoverflow.com/questions/53772331/vue-html-js-how-to-download-a-file-to-browser-using-the-download-tag
      const blob = new Blob([this.create_adiv3()], { type: 'application/text' })
      const link = document.createElement('a')
      link.download = "SimpleHAM.adiv3.txt";
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    },
    download_data_json() {
      // https://stackoverflow.com/questions/53772331/vue-html-js-how-to-download-a-file-to-browser-using-the-download-tag
      let res_dict = {
        own_callsign: this.own_callsign,
        freq: this.freq,
        logs: this.logs,
      }
      const blob = new Blob([JSON.stringify(res_dict)], { type: 'application/json' })
      const link = document.createElement('a')
      link.download = "SimpleHAM-data.json";
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    },

    // spike_process_prefix() {
    //   // https://raw.githubusercontent.com/YuYanDev/callsign/master/src/lib/HamCallsignDB/cty.json
    //   let resdict_one = {};
    //   let resdict_two = {};
    //   let resdict_three = {};
    //   let resdict_four = {};
    //   fetch("callsignindexone.json").then(response => response.json()).then(json => {
    //     json.forEach(element => { 
    //       resdict_one[element.prefix] = element.area  + " (" + element.prefix + ")";
    //       });
    //     });
    //   fetch("callsignindexthree.json").then(response => response.json()).then(json => {
    //     json.forEach(element => { 
    //       resdict_three[element.prefix] = element.area  + " (" + element.prefix + ")";
    //       });
    //     });
    //   fetch("callsignindexfour.json").then(response => response.json()).then(json => {
    //     json.forEach(element => { 
    //       resdict_four[element.prefix] = element.area  + " (" + element.prefix + ")";
    //       });
    //     });
    //   // console.log(resdict_one);
    //   fetch("callsignindextwo.json").then(response => response.json()).then(json => {
    //     json.forEach(element => { 
    //       resdict_two[element.prefix] = element.area + " (" + element.prefix + ")";
    //       let pre_split = element.prefix.split("");
    //       // console.log(element.prefix + " -> " + pre_split + " : " + element.area);
    //       if (resdict_one[pre_split[0]] != undefined ) {
    //         if (! resdict_one[pre_split[0]].toLowerCase().includes(element.area.toLowerCase()) ) {
    //           resdict_one[pre_split[0]] = resdict_one[pre_split[0]] + ", " + element.area + " (" + element.prefix + ")";
    //         }
    //       }
    //       else {
    //         resdict_one[pre_split[0]] = element.area + " (" + element.prefix + ")";
    //       }
    //       });
    //     // console.log(resdict_one);
    //     const blob = new Blob([JSON.stringify(resdict_one)], { type: 'application/json' })
    //     const link = document.createElement('a')
    //     link.download = "prefix_one.json";
    //     link.href = URL.createObjectURL(blob)
    //     link.click()
    //     URL.revokeObjectURL(link.href)

    //     // console.log(resdict_two);
    //     const blob2 = new Blob([JSON.stringify(resdict_two)], { type: 'application/json' });
    //     const link2 = document.createElement('a');
    //     link2.download = "prefix_two.json";
    //     link2.href = URL.createObjectURL(blob2);
    //     link2.click();
    //     URL.revokeObjectURL(link2.href);

    //     // console.log(resdict_two);
    //     const blob3 = new Blob([JSON.stringify(resdict_three)], { type: 'application/json' });
    //     const link3 = document.createElement('a');
    //     link3.download = "prefix_three.json";
    //     link3.href = URL.createObjectURL(blob3);
    //     link3.click();
    //     URL.revokeObjectURL(link3.href);

    //     // console.log(resdict_two);
    //     const blob4 = new Blob([JSON.stringify(resdict_four)], { type: 'application/json' });
    //     const link4 = document.createElement('a');
    //     link4.download = "prefix_four.json";
    //     link4.href = URL.createObjectURL(blob4);
    //     link4.click();
    //     URL.revokeObjectURL(link4.href);
    //   });
    // },

  }
}
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

.btn {
  display: inline-block;
  border: none;
  background: #555;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}

.btn:hover {
  background: #666;
}

.smaller {
  font-size: small;
}
</style>
