<template>
    <div>
        <form @submit="setOwnCallsign">
            <input type="text" v-model="own_callsign" name="freq" placeholder="Your own callsign" @change="valueChanged" @input="valueChanged">
            <input type="submit" value="Set" v-bind:class="{ btn :  valueChangedFlag < 1 , redbgclass: valueChangedFlag }" >
        </form>
    </div>
</template>

<script>
import {v4 as uuid} from 'uuid';
export default {
    name: "OwnCallsign",
    data() {
        return {
            own_callsign: '',
            valueChangedFlag: 0,
        }
    },
    mounted() {
        if (this.isLocalStorage()) {
            if (localStorage.own_callsign) {
                this.own_callsign = localStorage.own_callsign;
            }
        }
    },
    methods: {
        isLocalStorage() {
            var test = 'test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
            } catch(e) {
                return false;
            }
        },
        setOwnCallsign(e) {
            e.preventDefault();
            const newCallsign = {
                id: uuid(),
                own_callsign: this.own_callsign,
            }
            this.valueChangedFlag = 0;
            // Send up to parent (parent component?)
            this.$emit('set-own-callsign', newCallsign);
        },
        valueChanged() {
            this.valueChangedFlag = 1;
        }
    }
}
</script>

<style scoped>
    form {
        display: flex;
    }

    input[type="text"] {
        flex: 10;
        padding: 5px;
    }

    input[type="submit"] {
        flex: 2;
    }

    .redbgclass {
        background-color: #FF0000;
        background: #FF0000;
        display: inline-block;
        border: none;
        color: #000;
        padding: 7px 20px;
        cursor: pointer;
    }
</style>