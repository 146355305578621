<template>
    <div>
        <form @submit="addComments">
            <input type="text" v-model="comments" name="comments" placeholder="Comments (Optional: signal report, grid locator, portable, transceiver...)" @change="valueChanged" @input="valueChanged">
            <input type="submit" value="Set"  v-bind:class="{ btn :  valueChangedFlag < 1 , redbgclass: valueChangedFlag }" >
        </form>
    </div>
</template>

<script>
import {v4 as uuid} from 'uuid';
export default {
    name: "Comments",
    data() {
        return {
            comments: '',
            valueChangedFlag: 0,
        }
    },
    methods: {
        addComments(e) {
            e.preventDefault();
            const newComments = {
                id: uuid(),
                gmt_datetime: "XXX TODO",
                comments: this.comments,
            }
            this.valueChangedFlag = 0;
            // Send up to parent (parent component?)
            this.$emit('set-comments', newComments);
        },
        valueChanged() {
            this.valueChangedFlag = 1;
        }
    }
}
</script>

<style scoped>
    form {
        display: flex;
    }

    input[type="text"] {
        flex: 10;
        padding: 5px;
    }

    input[type="submit"] {
        flex: 2;
    }

    .redbgclass {
        background-color: #FF0000;
        background: #FF0000;
        display: inline-block;
        border: none;
        color: #000;
        padding: 7px 20px;
        cursor: pointer;
    }
</style>