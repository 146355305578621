<template>
    <div>
        <form @submit="addLog">
            <input autocomplete="off" type="text" v-model="peer_callsign" name="peer_callsign" placeholder="Peer callsign (and record log)" v-on:input="peer_callsign = $event.target.value" @change="valueChanged" @input="inputUpdate">
            <input type="submit" value="Submit" v-bind:class="{ btn :  valueChangedFlag < 1 , redbgclass: valueChangedFlag }" >
        </form>
        <div class="detail_div">{{ peer_callsign.toUpperCase() }} {{ (country_details.length > 1) ? " : " : "" }} {{country_details}} </div>
    </div>
</template>

<script>
import {v4 as uuid} from 'uuid';
export default {
    name: "AddLog",
    data() {
        return {
            peer_callsign: '',
            valueChangedFlag: 0,
            country_details: '',
            prefix_one: {},
            prefix_two: {},
            prefix_three: {},
            prefix_four: {},
        }
    },
    created() {
        // console.log("fetching JSON here and fill up local data");
        fetch("prefix_one.json").then(response => response.json()).then(json => {
            this.prefix_one = json;
        });
        fetch("prefix_two.json").then(response => response.json()).then(json => {
            this.prefix_two = json;
        });
        fetch("prefix_three.json").then(response => response.json()).then(json => {
            this.prefix_three = json;
        });
        fetch("prefix_four.json").then(response => response.json()).then(json => {
            this.prefix_four = json;
        });
    },
    methods: {
        addLog(e) {
            e.preventDefault();
            if (this.peer_callsign != "")
            {
                const newLog = {
                    id: uuid(),
                    peer_callsign: this.peer_callsign.toUpperCase(),
                    country_details: this.country_details,
                    logged: false 
                }
                this.valueChangedFlag = 0;
                // Send up to parent (parent component?)
                this.$emit('add-log', newLog);
                // this.peer_callsign='';       // We don't clear previous peer anymore...
            } // end deal with empty callsign logs
        },
        valueChanged() {
            this.valueChangedFlag = 1;
        },
        inputUpdate() {
            // console.log("display countri(es) from JSON dicts on input in form");
            if (this.peer_callsign.length == 1) {
                this.country_details = this.prefix_one[this.peer_callsign.toUpperCase()];
            }
            else if (this.peer_callsign.length == 2) {
                if (this.prefix_two[this.peer_callsign.toUpperCase()] == undefined) {
                    // if no hit in prefix_two, then no need to update country_details, that's why we commented the line below
                    // this.country_details = this.prefix_one[this.peer_callsign.split("")[0].toUpperCase()]; 
                }
                else {
                    this.country_details = this.prefix_two[this.peer_callsign.toUpperCase()];
                }
            }
            else if (this.peer_callsign.length == 3 && this.prefix_three[this.peer_callsign.toUpperCase()] != undefined) {
                this.country_details = this.prefix_three[this.peer_callsign.toUpperCase()];
            }
            else if (this.peer_callsign.length == 4 && this.prefix_four[this.peer_callsign.toUpperCase()] != undefined) {
                this.country_details = this.prefix_four[this.peer_callsign.toUpperCase()];
            }
            else if (this.peer_callsign.length == 0) {
                this.country_details = "";
            }
        },
    }
}
</script>

<style scoped>
    form {
        display: flex;
    }

    input[type="text"] {
        flex: 10;
        padding: 5px;
    }

    input[type="submit"] {
        flex: 2;
    }

    .redbgclass {
        background-color: #FF0000;
        background: #FF0000;
        display: inline-block;
        border: none;
        color: #000;
        padding: 7px 20px;
        cursor: pointer;
    }

    .detail_div {
        flex: 10;
    }
</style>
