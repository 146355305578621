<template>
<div>Copyright DRD21</div>
</template>

<script>
// URD : Unified Research and Development for 21st century
// DRD : Distributed Research and Development for 21st century
// DRD21.COM : DRD21 A.G. or DRD21 Inc.
export default {
}
</script>

<style scoped>
div {
    position: fixed;
    bottom: 0%;
    font-size: xx-small;
}
</style>