<template>
    <div id="gmt_time">
        <p>{{gmt_time}}</p>
    </div>
</template>

<script>
export default {
    name: "GmtTime",
    data() {
        return {
            gmt_time: 'Enjoy your HAM session!',
            interval: null,
            time: null,
            date: null
        }
    },
    // https://stackoverflow.com/questions/64914757/realtime-clock-with-vue-js
    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval)
    },
    created() {
        // update the time every second
        this.interval = setInterval(() => {
            // Concise way to format time according to system locale.
            // In my case this returns "3:48:00 am"
            this.time = Intl.DateTimeFormat(navigator.language, {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
            }).format();
            this.date = new Date();
            this.gmt_time = this.date.toUTCString();
        }, 1000)
    }
}
</script>

<style scoped>
    div {
        display: flex;
    }

    p {
        flex: 10;
        padding: 5px;
    }
</style>