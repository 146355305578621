<template>
    <div class="log-item" v-bind:class="{'is-logged':log.logged}">
        <p>
            <input type="checkbox" v-on:change="markLogged">
            {{log.peer_callsign}}, {{log.freq}} ({{log.band}}), {{log.date.substr(0,4)}}-{{log.date.substr(4,2)}}-{{log.date.substr(6,2)}} {{log.gmt_time.substr(0,2)}}:{{log.gmt_time.substr(2,2)}} UTC, {{log.comments}}
            <button @click="$emit('del-log', log.id)" class="del">X</button>
        </p>
    </div>
</template>

<script>
export default {
    name: "LogItem",
    props: ["log"],
    methods: {
        markLogged() {
            this.log.logged = !this.log.logged;
        }
    }
}


</script>

<style scoped>
    .log-item {
        background: #f4f4f4;
        padding: 10px;
        border-bottom: 1px #ccc dotted;
    }

    .is-logged {
        text-decoration: line-through;
    }

    .del {
        background: #ff0000;
        color: #fff;
        border: none;
        padding: 5px 9px;
        border-radius: 50%;
        cursor: pointer;
        float: right;
    }
</style>
