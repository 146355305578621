import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// https://github.com/keen-on-design/vue-flash-message
// https://www.npmjs.com/package/vue-flash-message
// 
// alternative?: 
// https://vuejsexamples.com/single-flash-message-with-vue/
// https://www.npmjs.com/package/@smartweb/vue-flash-message
import VueFlashMessage from 'vue-flash-message';
Vue.use(VueFlashMessage, {
  messageOptions: {
    timeout: 5000,
    pauseOnInteract: true
  }
});
require('vue-flash-message/dist/vue-flash-message.min.css');

new Vue({
  render: h => h(App),
}).$mount('#app')
