<template>
    <div>
        <form @submit="setFreq">
            <input type="text" v-model="freq" name="freq" placeholder="Frequency" @change="valueChanged" @input="valueChanged">
            <input type="submit" value="Set"  v-bind:class="{ btn :  valueChangedFlag < 1 , redbgclass: valueChangedFlag }" >
        </form>
    </div>
</template>

<script>
import {v4 as uuid} from 'uuid';
export default {
    name: "Freq",
    data() {
        return {
            freq: '',
            valueChangedFlag: 0,
        }
    },
    mounted() {
        if (this.isLocalStorage()) {
            if (localStorage.freq) {
                this.freq = localStorage.freq;
            }
        }
    },
    methods: {
        isLocalStorage() {
            var test = 'test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
            } catch(e) {
                return false;
            }
        },
        setFreq(e) {
            e.preventDefault();
            const newFreq = {
                id: uuid(),
                gmt_datetime: "XXX TODO",
                freq: this.freq,
            }
            this.valueChangedFlag = 0;
            // Send up to parent (parent component?)
            this.$emit('set-freq', newFreq);
        },
        valueChanged() {
            this.valueChangedFlag = 1;
        }
    }
}
</script>

<style scoped>
    form {
        display: flex;
    }

    input[type="text"] {
        flex: 10;
        padding: 5px;
    }

    input[type="submit"] {
        flex: 2;
    }

    .redbgclass {
        background-color: #FF0000;
        background: #FF0000;
        display: inline-block;
        border: none;
        color: #000;
        padding: 7px 20px;
        cursor: pointer;
    }
</style>